import {getAccountData} from "../../components/library/helpers/local_storage";
import DateFormatter from "@iso/components/utility/DateFormatter";

const localDataName = "mateInvoice";
let today = new Date();

const getDataAccountInvoice = (newInvoice) => {
  let dataAccount = getAccountData();
  newInvoice.billFrom = dataAccount ? dataAccount.billFrom : newInvoice.billFrom;
  newInvoice.billFromAddress = dataAccount ? dataAccount.address : newInvoice.billFromAddress;
  newInvoice.billFromPhone = dataAccount ? dataAccount.phone : newInvoice.billFromPhone;
  newInvoice.billFromEmail =  dataAccount ? dataAccount.email : newInvoice.billFromEmail;
  newInvoice.billFromIban = dataAccount ? dataAccount.iban : newInvoice.billFromIban;
  newInvoice.nifFrom = dataAccount ? dataAccount.nif : newInvoice.nifFrom;
  newInvoice.companyFrom = dataAccount ? dataAccount.companyName : newInvoice.companyFrom;
  return newInvoice;
}

const newInvoice = {
  orderStatus: "draft",
  orderDate: DateFormatter.getLastDayMonthValue(today),
  orderMonth: today.getMonth(),
  currency: "€",
  billTo: "",
  billToAddress: "",
  billFrom: "MEHOCA SERVEIS SL",
  billFromAddress: "Carrer De Sa Volta 11-12, SANT LLUIS, Baleares",
  billFromPhone: "+34 971 15 15 91",
  billFromEmail: "info@menorcahomecare.com",
  billFromIban: "ES4700610034440231680111",
  nifFrom: "B57222770",
  companyFrom: "MEHOCA SERVEIS SL",
  invoiceList: [
    {
      key: 1,
      itemName: "",
      itemType: "A",
      noTaxable: false,
      costs: 0,
      qty: 1,
      price: 0,
      createdAt: DateFormatter.getLastDayMonthValue(today),
    },
  ],
  taxable: 0,
  subTotal: 0,
  vatRate: 21,
  vatPrice: 0,
  totalCost: 0,
  emailSent: false,
  type: 'invoice', /* type: invoice or note */
  deleted_at: null,
};

const labelsInvoice = {
  invoice: {
    singular: "Invoice",
    plural: "Invoices"
  },
  note: {
    singular: "Nota",
    plural: "Notas"
  }
}

const initialListView = {
  top: 0,
  currentInvoice: null,
  loading: false,
  selected: [],
  isRangePicker: true,
  singleResult: null,
  startDateId: "start_date_id",
  endDateId: "end_date_id",
  filter: {
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(new Date().getFullYear()+1, 0, 0, 23, 59),
    billToId: null,
    billToName: "",
    orderStatus: null,
    invoice_serie: null,
    invoiceSerieName: "",
    type: null
  }
}

export { localDataName, newInvoice, labelsInvoice, initialListView, getDataAccountInvoice };
